import * as React from "react"
import Layout from "../components/layout";
import {Link} from "gatsby";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {RichText} from "prismic-reactjs";
import {Image} from "react-bootstrap";
import { graphql } from 'gatsby';
import GatsbyLink from "../components/gatsby-link";
import CallToAction from "../components/call-to-action";

const TeamPage = ({ data }) => {

  const content = data.prismicTeam.data;
  const people = data.allPrismicTeamDetail.nodes;
  return (
    <Layout title={RichText.asText(content.titel.raw)}>
      <section>
        <Row className="flex-md-row flex-column-reverse">
          <Col>
            <h1>
              {RichText.asText(content.titel.raw)}
            </h1>
            <RichText render={content.beschreibung.raw} serializeHyperlink={GatsbyLink}/>
          </Col>
        </Row>
      </section>
      <section>
        <Row className="flex-md-row flex-column-reverse">
          <Col>
            <RichText render={content.story.raw} serializeHyperlink={GatsbyLink}/>
          </Col>
        </Row>
      </section>
      <section>
        <Row>
          <Col>
            <h2>{RichText.asText(content.teammember_titel.raw)}</h2>
            <div className="mt-3 d-flex flex-column flex-md-row flex-wrap justify-content-around align-items-center align-items-sm-start align-items-md-center">
              { people.map(person => {
                return (
                  <Link className="author-link" key={person.uid} to={person.url}>
                    <div className="d-flex flex-column flex-sm-row flex-md-column justify-content-center align-items-center m-3">
                      <Image className="img-avatar mb-2 mr-sm-2 mb-md-2" src={person.data.portrait.fixed.src} alt={RichText.asText(person.data.name.raw)} height={125} width={125}/>
                      <h3 style={{ fontSize: '1.4rem' }}>{RichText.asText(person.data.name.raw)}</h3>
                    </div>
                  </Link>
                );
              })}
            </div>
          </Col>
        </Row>
      </section>
      <CallToAction/>
    </Layout>
  )
}

export const query = graphql`
query TeamPageQuery {
  prismicTeam {
    data {
      beschreibung {
        raw
      }
      story {
        raw
      }
      teammember_titel {
        raw
      }
      titel {
        raw
      }
    }
  }
  allPrismicTeamDetail(sort: {fields: data___order, order: ASC}) {
    nodes {
      url
      uid
      data {
        name {
          raw
        }
        portrait {
          fixed {
            src
          }
        }
      }
    }
  }
}
`

export default TeamPage
