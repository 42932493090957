import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RichText from "prismic-reactjs/src/Component";
import GatsbyLink from "./gatsby-link";
import {graphql, Link, useStaticQuery} from "gatsby";
import Button from "react-bootstrap/Button";
import * as React from "react";
import {useInView} from "react-intersection-observer";

const CallToAction = () => {

  const [ctaRef, ctaInView, ] = useInView({
    threshold: 0.5,
    triggerOnce: true
  });

  const data = useStaticQuery(graphql`
    query CallToActionQuery {
      prismicHomepage {
        data {
          cta_beschreibung {
            raw
          }
          cta_titel {
            raw
          }
          cta_label
          cta_link {
            url
          }
        }
      }
    }
  `);

  const content = data.prismicHomepage.data;

  return (
    <section className={`mb-5 opacity-0 ${ctaInView ? 'slide-up' : ''}`} ref={ctaRef}>
      <Row>
        <Col className="text-center">
          <h2>{RichText.asText(content.cta_titel.raw)}</h2>
          <div className="large">
            <RichText render={content.cta_beschreibung.raw} serializeHyperlink={GatsbyLink}/>
          </div>
          <Link to={content.cta_link.url}>
            <Button>{content.cta_label}</Button>
          </Link>
        </Col>
      </Row>
    </section>
  )
}

export default CallToAction
